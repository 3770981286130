<template>
  <v-container class="px-2">
    <v-card flat class="transparent mx-auto mt-0">
      <v-card-text class="pa-0">
        <v-row dense>
          <v-col cols="12" md="4">
            <v-switch
              v-model="receiveText"
              color="accent"
              label="Receive text?"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-switch
              v-model="isRecovery"
              :label="`Is Recovery Phone?`"
              color="accent"
            />
          </v-col>
          <v-spacer />
          <v-col cols="12" md="3">
            <v-select
              v-model="type"
              prepend-icon="mdi-view-list"
              :items="phoneTypes"
              label="Phone Type"
              outlined
              item-text="text"
              item-value="value"
            ></v-select>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-select
              v-model="countrycode"
              prepend-icon="mdi-flag"
              :items="countriesList"
              label="Country Code"
              outlined
              item-text="country_phone"
              item-value="iso"
            ></v-select>
          </v-col>
          <v-row>
            <template v-if="!isus">
              <v-col cols="12" md="2">
                <v-text-field
                  label="Area"
                  v-model="area"
                  required
                  type="number"
                  prepend-icon="mdi-view-quilt"
                  :counter="5"
                  @input="$v.area.$touch()"
                  @blur="$v.area.$touch()"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  label="Phone Number"
                  v-model="phnum"
                  required
                  type="number"
                  prepend-icon="mdi-phone"
                  :counter="10"
                  @input="$v.phnum.$touch()"
                  @blur="$v.phnum.$touch()"
                />
              </v-col>
            </template>
            <template v-else>
              <v-col cols="12" md="2">
                <v-text-field
                  label="Area"
                  v-model="area"
                  required
                  type="number"
                  prepend-icon="mdi-view-quilt"
                  :counter="3"
                  @input="$v.area.$touch()"
                  @blur="$v.area.$touch()"
                />
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  label="Phone Number"
                  v-model="phnum"
                  required
                  type="number"
                  prepend-icon="mdi-phone"
                  :counter="7"
                  @input="$v.phnum.$touch()"
                  @blur="$v.phnum.$touch()"
                />
              </v-col>
            </template>
            <v-col cols="12" md="3">
              <v-text-field
                label="Extension"
                v-model="extension"
                required
                type="number"
                prepend-icon="mdi-fullscreen-exit"
                :counter="3"
                @input="$v.extension.$touch()"
                @blur="$v.extension.$touch()"
              />
            </v-col>
          </v-row>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-0 pb-0">
        <v-spacer />
        <BaseActionButton
          class="charcoal paper--text"
          :large="!isPhone"
          v-bind="$attrs"
          :disabled="this.$v.$anyError"
          @clickedThis="savePhone"
        />
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
//import { required } from 'vuelidate/lib/validators'
import axios from 'axios'
import phoneTypes from '@/json/phonetypes.json'
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  props: {
    accountInfo: Object
  },
  data: () => ({
    countrycode: '',
    area: '',
    phnum: '',
    extension: '',
    country: '',
    type: 'Cell',
    isprogressActive: true,
    receiveText: true,
    isRecovery: true,
    countriesList: [],
    phoneTypes: phoneTypes,
    phoneid: null
  }),
  mounted() {
    this.loadCountries()
    this.receiveText = this.accountInfo.receive_text == 'Yes' ? true : false
    this.phoneid = this.accountInfo.phoneid
    this.type = this.accountInfo.phone_type
    this.area = this.accountInfo.area
    this.phnum = this.accountInfo.phnum
    this.extension = this.accountInfo.extension
    this.countrycode = this.accountInfo.countrycode
    this.isRecovery = this.accountInfo.is_recovery_phone == 'Yes' ? true : false
  },
  validations: {},
  computed: {
    areaMax() {
      if (this.isus()) return 3
      else return 5
    },
    isus() {
      return this.countrycode == 'US' || this.countrycode == 'CA'
    }
  },
  methods: {
    loadCountries() {
      {
        return axios
          .get(this.$store.state.config.baseURL + '/util/countries/all', {})
          .then(response => {
            if (response.status == 200) {
              this.countriesList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    savePhone() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      } else {
        const phone = {
          phoneid: this.phoneid,
          countrycode: this.countrycode,
          phnum: this.phnum,
          area: this.area,
          extension: this.extension,
          country: this.country,
          type: this.type,
          is_progressActive: this.isprogressActive ? 'Yes' : 'No',
          is_recovery: this.isRecovery ? 'Yes' : 'No',
          receive_text: this.receiveText ? 'Yes' : 'No',
          is_forward: 'No'
        }
        if (this.phoneid != null) {
          return axios
            .put(this.$store.state.config.baseURL + '/users/phone', phone)
            .then(response => {
              if (response.status == 200) {
                this.$emit('updateInfo')
                this.$store.dispatch(
                  'notification/addNotification',
                  'Phone was succesfully saved.',
                  2000,
                  true,
                  {
                    root: true
                  }
                )
              } else {
                this.$store.dispatch(
                  'notification/addErrors',
                  response.data.errors,
                  5000,
                  {
                    root: true
                  }
                )
                return
              }
            })
        } else {
          return axios
            .post(this.$store.state.config.baseURL + '/users/phone', phone)
            .then(response => {
              if (response.status == 200) {
                this.phoneid = response.data.data.phoneid
                this.$emit('updateInfo')
                this.$store.dispatch(
                  'notification/addNotification',
                  'phone was succesfully saved.',
                  2000,
                  true,
                  {
                    root: true
                  }
                )
              } else {
                this.$store.dispatch(
                  'notification/addErrors',
                  response.data.errors,
                  5000,
                  {
                    root: true
                  }
                )
                return
              }
            })
        }
      }
    }
  }
}
</script>
